import React, { useEffect, useState } from "react";
import Layout from "../components/common/Layout";
import Success from "../components/success/Success";
import Fade from "react-reveal/Fade";

const SuccessPage = () => {
  return (
    <Layout
      meta={{
        description:
          "Museum-quality posters made on thick matte paper. Add a wonderful accent to your room and office with these posters that are sure to brighten any environment.",
        title: "LWE Art - Paintings, Posters & Art Prints",
        type: "website",
      }}
      title={"LWE Art - Paintings, Posters & Art Prints"}
      noindex={true}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <Success />
        </Fade>
      </main>
    </Layout>
  );
};

export default SuccessPage;
