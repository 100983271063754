import React from "react";
import styles from "./Success.module.scss";
import { CheckIcon } from "@heroicons/react/24/outline";
import Button from "../common/Button";

const Success = ({}) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div>
          <div className={styles.iconContainer}>
            <div className={styles.icon}>
              <CheckIcon width={50} color="#fff" />
            </div>
          </div>
          <h2>Payment completed!</h2>
          <p>
            The payment has been successfully completed. You will receive an
            email with the receipt shortly. We'll start packing and send your
            item as soon as possible. Thank you for your purchase!
          </p>
          <Button href="/" variant="secondary">
            Back to home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Success;
